const introLR = [
  "Con estas actividades te ofrecemos la posibilidad de viajar con nosotros. Los niños aprenderán diferentes contenidos curriculares, trabajarán en equipo, se divertirán, imaginarán, sonreirán… Sin lugar a dudas, nuestro equipo hará que lo pasen en grande, desde que llegan los niños, hasta que se van a casa.",
  "En Aristas somos especialistas en diseñar actividades para que los niños aprendan jugando. A través de actividades sensoriales, cognitivas y lúdicas, los niños aprenderán diferentes contenidos curriculares, trabajarán en equipo, se divertirán, imaginarán, sonreirán ... Sin lugar a dudas, nuestro equipo hará que lo pasen en grande."
];

// Ludico-Recreativas act00
const act00 = [
  {
    nombre: "Aristas Chef Navideño",
    texto: [
      "La cocina enseña a los niños habilidades para enfrentar la vida. Cocinar, disfrutar con la comida, desarrollar la imaginación… sin lugar a dudas, siempre es atractivo para los niños en estas edades. Aristas chef es la mejor opción para los apasionados de la cocina y del programa televisivo máster chef junior."
    ],
    imagen: "aristas-chef-navidad.jpg"
  },
  {
    nombre: "Aventura en el Espacio",
    texto: [
      "La astronomía puede parecer un tema complejo y fuera del alcance de los niños/as, pero nada más lejos de la realidad… El día, la noche, el Sol, las estrellas, la Luna, etc.",
      "Aristas atrae y motiva la inagotable curiosidad de nuestros futuros astronautas. Si a esto le  unimos los planetas, las naves espaciales, el astronauta, los cometas… la diversión e ilusión están aseguradas."
    ],
    imagen: "aventura-en-el-espacio.jpg"
  },
  {
    nombre: "Día Indio",
    texto: [
      "Los niños conocerán a un jefe indio sin tribu que les pedirá que le ayuden a recuperar su caballo, a montar un tótem sagrado y ¡podrán entrar a su tipi! Aprenderemos danzas y juegos indios, pintaremos nuestra propia pluma y fundaremos nuestra tribu."
    ],
    imagen: "dia-indio.jpg"
  },
  {
    nombre: "Érase una vez el circo",
    texto: [
      "Los niños y niñas disfrutarán de un mágico día de circo con los personajes más dicharacheros y las actividades más divertidas. Aparecerán nuestros amigos Cabeza de Melón Y Melocotón, quienes nos harán reír con la participación de muchos más personajes y sorprendentes aventuras. Psicomotricidad, juegos de equilibrio, mímica, malabares… ¡un estupendo día de circo!"
    ],
    imagen: "erase-una-vez-el-circo.jpg"
  },
  {
    nombre: "La Castañera",
    texto: [
      "Cuando llega el otoño las hojas empiezan a caer y todo se convierte en una postal perfecta… es entonces cuando aparece nuestra amiga la Castañera y el duende Agapito, quienes nos dan una lección sobre lo importante que es compartir y respetarnos.",
      "Mediante juegos, talleres y diferentes actividades los niños descubrirán y vivirán el otoño de una manera muy divertida."
    ],
    imagen: "la-castañera.jpg"
  },
  {
    nombre: "Érase una vez el Cuerpo Humano",
    texto: [
      "El doctor “Turuleta” anda un poco despistado y confunde la oreja con el brazo y la nariz con el pie. Necesita la ayuda de los niños y niñas para volver a aprender todo lo que sabía ¡Pobre Turuleta!"
    ],
    imagen: "erase-una-vez-el-cuerpo-humano.jpg"
  },
  {
    nombre: "La Vuelta al Mundo",
    texto: [
      "La vuelta al mundo nos permite adentrarnos en la magia de conocer otras culturas diferentes, conocer cómo viven otras personas. Todo ellos a través de juegos, gymkanas y muchas más actividades. ¿Te atreves a viajar con nosotros?"
    ],
    imagen: "la-vuelta-al-mundo.jpg"
  },
  {
    nombre: "Los Oficios",
    texto: [
      "En esta excursión los niños conocerán diferentes oficios, y lo vivirán desde bien cerca. En distintas estaciones descubrirán las labores que hacen los agricultores, albañiles, coserán su propio botón, se sentirán científicos y… ¡conocerán qué entrenamientos realizan los bomberos!"
    ],
    imagen: "los-oficios.jpg"
  },
  {
    nombre: "Mundo de Fantasía",
    texto: [
      "Aristas introduce a los niños en un mundo de fantasía que les encanta (hadas, duendes, brujas…) y les ayuda a descubrir a estas pequeñas criaturas provenientes de un mundo mágico. Mediante juegos y actividades conseguiremos que los niños expresen y comuniquen experiencias, hechos, emociones, miedos, sentimientos y vivencias personales."
    ],
    imagen: "mundo-de-fantasia.jpg"
  },
  {
    nombre: "Pásalo Ciencia",
    texto: [
      "En esta actividad los niños tendrán la oportunidad de ver, descubrir y realizar sus propios experimentos así como aprender de manera lúdica y divertida algunas reacciones químicas con las que aprenderán y dejaran volar su imaginación. ¡Y se convertirán en científicos locos!"
    ],
    imagen: "pasalo-ciencia.jpg"
  },
  {
    nombre: "Peque Olimpiadas",
    texto: [
      "Aristas te ofrece la oportunidad de convertirte en un atleta olímpico por un día para  conseguir y superar los mayores records, sobre todo los records en diversión.",
      "Nos trasladaremos a la antigua Grecia, donde nuestros atletas harán carreras, tiro con arco, lanzamiento de disco, entre muchas otras actividades."
    ],
    imagen: "peque-olimpiadas.jpg"
  },
  {
    nombre: "Un día en la Prehistoria",
    texto: [
      "Aristas te ofrece la oportunidad de dar un salto hacia atrás en el tiempo hasta la prehistoria. Los niños realizarán actividades ambientadas en dicha época, experimentarán la forma de vida de nuestros antepasados y observarán los grandes hallazgos producidos en este periodo."
    ],
    imagen: "un-dia-en-la-prehistoria.jpg"
  },
  {
    nombre: "Yo también soy un Superhéroe",
    texto: [
      "Aristas te da la oportunidad de convertirte en tu superhéroe favorito por un día para salvar al mundo. Llevaremos cabo infinidad de actividades ambientadas en tus aventuras de comics favoritas. A lo largo del día lucharemos contra algunas de las mayores injusticias sufridas por los niños hoy en día como es el rechazo, la marginación o el acoso escolar entre otras."
    ],
    imagen: "yo-tambien-soy-un-superheroe.jpg"
  }
];

// Ocio-culturales act01
const act01 = [
  {
    nombre: "Acuario Santa Pola + Museo del Mar",
    texto: [
      "En esta excursión disfrutarás de un día para conocer en el acuario las especies más comunes del mar mediterráneo. Además nos adentraremos en el castillo de Santa Pola para conocer el museo de la mar donde conoceremos la vida marítima y la evolución de Santa Pola y su puerto a lo largo del tiempo los monitores más aventureros te acompañarán en  esta increíble experiencia además de hacer actividades y juegos en el maravilloso parque palmeral de Santa Pola."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Alcoy Cultural",
    texto: [
      "Aristas nos ofrece un recorrido cultural por la ciudad de Alcoy abarcando algunos de los museos y obras más emblemáticas de la ciudad. Conoceremos el primer museo de bomberos de la Comunidad Valenciana, el MUBOMA. Un espacio cuyo contenido innovador está destinado a dar a conocer la importancia de esta profesión.",
      "También visitaremos el emblemático TIRISITI, un montaje teatral con títeres que se celebra desde el siglo XIX y es característico en Navidad. Nuestro recorrido cultural continúa visitando uno de los refugios más importantes de la Guerra Civil. Posteriormente nos trasladaremos a la Font Rocha a comer y realizar actividades y juegos."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Caravaca Cultural + Fuentes del Marqués",
    texto: [
      "A través de las callejuelas medievales de la Ciudad de la Cruz conoceremos su historia y su pasado. Hablaremos sobre los Caballos del Vino, las fortificaciones árabes, la reconquista cristiana y, tras visitar el camarín de la Cruz, conoceremos la leyenda del milagro que convirtió una pequeña villa de frontera en Ciudad Santa.",
      "A continuación nos desplazaremos a Fuentes del Marqués."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Cartagena Cultural",
    texto: [
      "Aristas te invita a viajar a través de los dos mil años de historia de la Ciudad. En nuestra visita podremos introducirnos en las entrañas del Teatro Romano e imaginar la grandiosidad de su pasado. Regresaremos al puerto para tomar un barco que nos llevará hasta el Fuerte de Navidad, una de las fortalezas que ha protegido durante siglos la línea de costa de barcos invasores.",
      "A la vuelta y si lo consideras adecuado, podremos visitar el ARQUA o contemplar a vista de pájaro la ciudad desde el Castillo de la Concepción."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Castillo Biar + Santuario",
    texto: [
      "En esta excursión disfrutarás de un día aventurero visitando el castillo medieval de Biar contemplando desde su torre más alta, la extensión de tierra y maravillosas vistas por las que luchaban los diferentes reinos.",
      "Los monitores más exploradores y aventureros te acompañan en esta increíble experiencia donde visitaremos también el santuario de Biar y realizar juegos de aventura en este increíble entorno."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Castillo Alicante + Palmeral",
    texto: [
      "Caballeros y princesas acudirán en nuestra búsqueda para contarnos la historia de este precioso castillo. De una forma dramatizada y didáctica nos invitarán a que nos adentremos en su interior para conocer todos sus rincones así como la forma de vida de aquella época."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Castillo Jumilla + Jardín Botánico",
    texto: [
      "Caballeros y princesas acudirán en nuestra búsqueda para contarnos la historia de este precioso castillo. De una forma dramatizada y didáctica nos invitarán a que nos adentremos en su interior para conocer todos sus rincones así como la forma de vida de aquella época."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Cuevas del Puerto + Santuario V. De La Esperanza",
    texto: [
      "La Cueva del Puerto es una de las cuevas de mayor recorrido horizontal de nuestra Región, con cerca de 5 km topografiados y un desnivel de 114 metros de profundidad. La geología del interior está compuesta por galerías y salas de gran belleza natural.",
      "Tras esta espectacular visita a las profundidades nos trasladaremos al Santuario de la Esperanza donde realizaremos multitud de actividades y juegos con los niños."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Elche Cultural",
    texto: [
      "Disfruta con Aristas de un divertido viaje por toda la ciudad de Elche. El Palmeral de Elche, junto con la Dama de Elche, conforma los símbolos de identidad ilicitanos y son unos de los principales atractivos turísticos de esta ciudad de tradición industrial, donde llevaremos a cabo multitud de actividades y visitas de interés."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Granja Escuela",
    texto: [
      "Los animales son un tema muy atractivo y motivador para los niños/as de estas edades. Es frecuente que algunos niños/as tengan mascotas en casa, que les ayudan a ser más responsables, aprender a respetar la naturaleza y crecer desarrollando un carácter más abierto y sociable. Esta salida ofrece un enriquecedor reencuentro con animales y con la naturaleza."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Ludoteca de Ibi + Casa Tápena",
    texto: [
      "La ludoteca de Ibi es un centro de tiempo libre que pone a disposición de los niños y niñas una colección de juguetes para usarse en la propia instalación. Tiene un espacio es de 250 m2 y un fondo lúdico de 350 juguetes distribuidos por rincones. Vehículos, juegos de mesa, disfraces, construcciones, médicos, juegos de mesa, miniaturas, muñecas, etc."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Murcia Cultural",
    texto: [
      "La capital de la Región nos ofrece diversos monumentos para disfrutar de una interesantísima mañana. Conoceremos los entresijos de la construcción de la Catedral, disfrutaremos de las leyendas de su construcción y conoceremos el porqué de su ubicación."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Museo de la Música (Barranda) + Fuentes del Marqués",
    texto: [
      "Muy cerca de Caravaca de la Cruz se localiza Barranda, donde se encuentra el Museo de la Música Étnica. Dentro de este interesante y divertido museo, se realizarán diferentes actividades, tales como: proyecciones audiovisuales, demostración y explicación didáctica de diferentes instrumentos del mundo, etc. Tras esta visita nos desplazaremos hasta las Fuentes del Marqués para realizar diferentes juegos y actividades relacionados con la música."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Museo de la Música (Bussot) + Cuevas de Canelobre",
    texto: [
      "El Museo de Música Étnica de Busot es una exquisita muestra de instrumentos sacados de todas las épocas, culturas y rincones del mundo pertenecientes a la colección del reconocido musicólogo Carlos Blanco Fadol, el mayor coleccionista de instrumentos étnicos del mundo.",
      "Tras esta visita nos trasladaremos a las cuevas de Canelobre Magnífico ejemplo de cavidad kárstica, las cuales albergan un espacio de más de 80.000 m2 al que se puede acceder por un túnel de 45 metros."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Museo Flotante + Parque de las Naciones",
    texto: [
      "Esta actividad se dividirá en dos partes importantes, donde los niños conocerán las partes de un submarino, la importancia de nuestra historia, y, además, afianzarán contenidos mediante actividades lúdicas. ¡Un día para dejar flotar la imaginación!"
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Orihuela Cultural",
    texto: [
      "En el corazón de la Vega Baja se esconde una ciudad cargada de Historia. Conocida como Tudmir en la Edad Media, Aristas te acerca a conocer de forma transversal el pasado de la Orihuela desde su legado musulmán y barroco, atendiendo a la figura de su escritor insigne, Miguel Hernández. Para completar la visita nos acercaremos al Museo Didáctico e Interactivo de la Ciencia. De esta forma viajaremos a través de la Historia hasta los nuevos descubrimientos científicos del siglo XXI."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Un día Minero",
    texto: [
      "La Sierra de Cartagena-La Unión contiene importantes yacimientos minerales metálicos, principalmente de plomo y zinc, lo que caracteriza a esta zona por una intensa actividad minera desde tiempo de cartagineses y romanos.",
      "La mina Agrupa Vicenta es el atractivo principal de todo el complejo, ya que se trata de la primera y única mina subterránea de la Región de Murcia musealizada y acondicionada para la visita del público."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Viaje en Tren a Elche",
    texto: [
      "Disfruta con Aristas de un divertido viaje en tren hasta la ciudad de Elche. El Palmeral de Elche, junto con la Dama de Elche, conforma los símbolos de identidad ilicitanos y son unos de los principales atractivos turísticos de esta ciudad de tradición industrial, donde llevaremos a cabo multitud de actividades y visitas de interés."
    ],
    imagen: "imagen.jpg"
  }
];

// Aventura act02
const act02 = [
  {
    nombre: "Multiaventura",
    texto: [
      "En esta excursión disfrutarás de un día aventurero con actividades de aventura adaptadas, juegos cooperativos, senderismo y tiro con arco, entre otras.",
      "Los monitores más exploradores y aventureros te acompañarán esta increíble experiencia donde observaremos la flora y fauna de nuestra Región."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Descenso de Río (Cañón de Almadenes)",
    texto: [
      "El descenso del río es, sin lugar a dudas, una aventura increíble. Durante esta jornada los niños conocerán la flora y fauna característica del Río Segura, y, además, realizaremos una parada para observar pinturas rupestres. Tras esta experiencia nos desplazaremos hasta el Santuario de la Virgen de la Esperanza para comer y hacer actividades. ¡Una excursión excelente y divertida!"
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Día de Pozas",
    texto: [
      "En esta excursión disfrutarás de un día aventurero con actividades de aventura adaptadas, juegos cooperativos, baño controlado, senderismo y tiro con arco, entre otras",
      "Los monitores más exploradores y aventureros te acompañarán en esta increíble experiencia donde observaremos la flora y fauna."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Fuentes del Río Algar",
    texto: [
      "En esta excursión disfrutarás de un día aventurero con actividades de aventura adaptadas, juegos cooperativos, baño controlado, senderismo, entre otras.",
      "Los monitores más exploradores y aventureros te acompañarán en esta increíble experiencia donde observaremos la flora y fauna así como la biodiversidad que nos rodea."
    ],
    imagen: "imagen.jpg"
  }
];

// Oficios act03
const act03 = [
  {
    nombre: "7TV + Mudem",
    texto: [
      "Conocer los medios de comunicación es un contenido interesante y atractivo para los niños en edad escolar. Durante esta visita, los niños podrán entrar en las instalaciones de la televisión para conocer de primera mano cómo se hace un programa de televisión. Del mismo modo en el museo de forma interactiva los niños conocerán parte de la muralla medieval andalusí, así como la historia de Molina desde el siglo XI hasta el XVI."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "CableWorld + Palmeral",
    texto: [
      "Conocer los medios de comunicación es un contenido interesante y atractivo para los niños en edad escolar. Durante esta visita, los niños podrán entrar en las instalaciones de la televisión para conocer de primera mano cómo se hace un programa de televisión."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Alfarería de Totana",
    texto: [
      "Los talleres de artes plásticas resultan gratificantes para el alumnado por su carácter práctico y creativo. Proporciona una vía para expresar la necesidad de crear latente en todo ser humano.",
      "Además, Aristas te permite conocer un antiguo horno cerámico con capacidad para 20.000 piezas y la recreación de una antigua Casa Argárica, cultura que controlaba el territorio totanero hace más de 3500 años."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Alfareria de Agost",
    texto: [
      "Los talleres de artes plásticas resultan gratificantes para el alumnado por su carácter práctico y creativo. Proporciona una vía para expresar la necesidad de crear latente en todo ser humano."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Almazara de Elche",
    texto: [
      "Almazara El Tendre es una empresa centenaria cuyos orígenes se remontan al 20 de Abril de 1839, cuando Don Joaquín Sempere García hereda la almazara de su suegro, Gaspar Ferrández. A pesar de la modernización en la evolución industrial, El Tendre se ha seguido manteniendo fiel al espíritu de la elaboración tradicional.",
      "Hasta 1990 la almazara continuó utilizando las prensas para moler la oliva y el aceite se envasaba a mano."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Bodega de Vino",
    texto: [
      "Griegos, romanos, fenicios, iberos… todos los pueblos de la Antigüedad han cultivado, alabado y disfrutado de los beneficios del vino y la vid. En esta excursión Aristas te propone conocer un oficio tan antiguo como actual, visitando bodegas punteras reconocidas a nivel internacional. En ellas observaremos todo el proceso de elaboración, desde la obtención del mosto hasta el envasado del vino."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Chocolates Pérez + Cuevas de Canelobre",
    texto: [
      "Aristas te invita a conocer el museo del chocolate y aprender de donde proviene el cacao, así como la evolución en los procesos, herramientas y maquinaria empleada en el proceso de elaboración.",
      "Además de todo ello, tras esta visita con Aristas llevarás a cabo una experiencia inolvidable visitando las cuevas de Canelobre."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Día de Radio",
    texto: [
      "Conocer los medios de comunicación es un contenido interesante y atractivo para los niños en edad escolar. Durante esta visita, los niños desarrollarán diferentes actividades temáticas, relacionadas con el medio de comunicación de la radio. Asimismo, los niños podrán entrar en las instalaciones, en pequeños grupos, para participar en el programa de radio."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Fábrica de Juguetes + Casa Tápena",
    texto: [
      "El Museo del Juguete es un centro para la conservación, la investigación y la difusión del patrimonio juguetero. Un espacio extraordinario que nos permite viajar al pasado y dejar que los juguetes nos hablen de las infancias vividas y los modos de entender el mundo en otras épocas.",
      "Los monitores más exploradores y aventureros te acompañarán esta increíble experiencia donde observaremos como se fabrican los juguetes y después mediante pruebas conseguiremos adentrarnos en un ¡¡¡ laberinto!!!"
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Museo del Turrón + Cuevas de Canelobre",
    texto: [
      "En esta excursión disfrutarás de un día aventurero con actividades de aventura adaptadas, juegos cooperativos, senderismo, entre otras.",
      "Los monitores más exploradores y aventureros te acompañarán esta increíble experiencia viendo cómo se elabora el turrón típico de la navidad y además visitaremos las cuevas del Canelobre."
    ],
    imagen: "imagen.jpg"
  }
];

// Parques Temáticos act04
const act04 = [
  {
    nombre: "Aquapark",
    texto: [
      "Aquapark es un parque acuático para disfrutar toda la familia, donde mayores y pequeños, pasarán una jornada inolvidable en su visita a nuestras instalaciones. Todas las actividades están perfectamente diferenciadas por edades y estaturas además de contar con un socorrista para cada una de ellas.",
      "El equipo de monitores de Aristas velará durante toda la jornada por la seguridad y divertimento de los niños."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Aquavera",
    texto: [
      "La diversión está asegurada en Aquavera, no sólo con la gran cantidad de atracciones acuáticas que nos ofrece el parque, sino también con las actividades que organiza Aristas y el espectacular equipo de monitores, el cual velará durante toda la jornada por la seguridad y divertimento de los niños."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Aquópolis",
    texto: [
      "La diversión está asegurada en Aquópolis, no sólo con la gran cantidad de atracciones acuáticas que nos ofrece el parque, sino también con las actividades que organiza Aristas y el espectacular equipo de monitores, el cual velará durante toda la jornada por la seguridad y divertimento de los niños."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Bioparc",
    texto: [
      "Bioparc Valencia es un parque zoológico español situado en la costa mediterránea especializado en la fauna africana, está dividido en cuatro zonas representando cuatro biomas: sabana seca, sabana húmeda, bosques del África ecuatorial y Madagascar.",
      "Cuenta con 80.000 metros cuadrados de superficie y alberga a más de 800 animales de 116 especies del continente africano en grandes espacios que reproducen el hábitat de cada animal."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Camping La Marina",
    texto: [
      "Al abordaje! Disfruta de un día de agua con los mejores y más divertidos monitores de Aristas donde realizaremos todo tipo de actividades acuáticas con actividades dirigidas toboganes, piscinas y juegos de agua.",
      "La Marina Resort & Camping, quedando así finalizado el parque acuático dentro de nuestras instalaciones. Cuenta con 10 toboganes tematizados, con pistas blandas, kamikaze y dos rápidos, de todos los ¡colores, curvas y gustos! Con un sistema especial de frenado que te permitirá disfrutar de la velocidad."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Ciudad de las Artes y las Ciencias",
    texto: [
      "Diviértete con la ciencia y descubre, de manera interactiva, su Bosque de Cromosomas; conviértete en astronauta visitando la Estación Espacial Internacional; y conoce los espectaculares efectos de la Bobina de Tesla en el Teatro de la Electricidad Y, si quieres alucinar, te mostraremos cómo el agua se convierte en vino en La ciencia a escena. Einstein y Newton estarán encantados de atenderte."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "L'Oceanografic",
    texto: [
      "Oceanográfico de Valencia es el acuario más grande de Europa. Ubicado dentro del complejo arquitectónico vanguardista de la Ciudad de las Artes y las Ciencias, este paraje reproduce fielmente los ecosistemas marinos más importantes."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Dinopark",
    texto: [
      "El parque temático y educativo DinoPark Algar, situado en un hermoso entorno cerca de Benidorm te sorprenderá con decenas de modelos robóticos, estáticos y sonoros de tamaño natural. DinoPark Algar cuenta también con zonas de juego, patio paleontológico, Cine 3D y jardín botánico de cactus. Lleva tu familia a un paseo de diversión y conocimientos."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Mini Hollywood",
    texto: [
      "Aristas te traslada al lejano oeste donde conoceremos personajes del western, disfrutaremos de las distintas actuaciones y shows, visitaremos la zona minera, la herrería, la oficina del sheriff y muchas más actividades, todo ello contextualizado con los mejores decorados de cines más importantes de toda Europa donde más películas de temática western se han rodado."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Polapark",
    texto: [
      "Pola Park es un parque de atracciones con 30 atracciones para disfrutar en familia o con los amigos. Pola Park ofrece un día de diversión sin límites. Paseos ajardinados, mucha iluminación y colores será el ambiente que te rodee durante tu aventura en Pola Park. Todo ello por supuesto acompañado de los monitores más divertidos de Aristas."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Río Safari Elche",
    texto: [
      "La visita incluye el recorrido por todo el zoo, pasando por la cueva de los reptiles, el templo de los tigres, la isla de los chimpancés, la sabana africana, el santuario del orangután de Borneo…",
      "Además, la ruta en el tren expedicionario os llevará por el interior de los recintos, a una proximidad asombrosa de los animales."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Terra Mítica",
    texto: [
      "Descubre las antiguas civilizaciones del Mediterráneo en Terra Mítica, Benidorm, el parque temático y de atracciones que te hará viajar directamente a la historia de Grecia, Egipto y Roma. Terra Mítica te hará disfrutar de tus mejores días del verano, con espectáculos y atracciones que te emocionarán y harán subir tus niveles de adrenalina."
    ],
    imagen: "imagen.jpg"
  },
  {
    nombre: "Terra Natura + AN",
    texto: [
      `Terra Natura es un parque de animales de nueva generación donde podrás descubrir la "Zooimmersión", un nuevo concepto en el diseño de hábitats que te permitirá tomar contacto con los animales a través de barreras invisibles a los ojos del ser humano.`,
      "Además de todo ello, durante los meses de verano Aristas te ofrece la posibilidad de visitar Aqua Natura, un parque acuático que alberga  en su interior diferentes zonas de ocio y diversión para que tanto los más pequeños como los más grandes disfruten en este refrescante espacio. Aqua Natura ofrece piscinas pensadas para los niños pero también para los más mayores. Un lugar para dejarse llevar por la suave corriente del Río Lento, con o sin flotador."
    ],
    imagen: "imagen.jpg"
  }
];

// Consolidacion datos

const act00_mod = act00.map((item, index) => ({
  key: index,
  tipo: "act00",
  ...item
}));
const act01_mod = act01.map((item, index) => ({
  key: 100 + index,
  tipo: "act01",
  ...item
}));
const act02_mod = act02.map((item, index) => ({
  key: 200 + index,
  tipo: "act02",
  ...item
}));
const act03_mod = act03.map((item, index) => ({
  key: 300 + index,
  tipo: "act03",
  ...item
}));
const act04_mod = act04.map((item, index) => ({
  key: 300 + index,
  tipo: "act04",
  ...item
}));

const datos = act00_mod.concat(
  act01_mod.concat(act02_mod.concat(act03_mod.concat(act04_mod)))
);

export default datos;
