import React from "react";

import "./style.scss";
import Helmet from "./common/helmet";
import NavBar from "./common/navbar";
import SubMenu from "./common/submenu";
import Excursiones from "./layouts/excursiones/excursiones";
import Footer from "./common/footer";

const Layout = ({ children }) => (
  <div>
    <Helmet />
    <SubMenu />
    <NavBar />
    <Excursiones />
    <Footer />
  </div>
);

export default Layout;
