import React from "react";

import TipoExcursion from "./tiposExcursiones/tipoExcursion";

const TiposExcursiones = ({ data, onOptionClick }) => (
  <div className="columns is-multiline is-centered">
    {data.map(item => (
      <TipoExcursion key={item.key} onClick={onOptionClick} data={item} />
    ))}
  </div>
);

export default TiposExcursiones;
