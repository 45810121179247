import React, { useState } from "react";

import Hero from "../../common/hero";
import TiposExcursiones from "./layout/tiposExcursiones";
import ListaItems from "./layout/tiposExcursiones/listaItems";

// Datos
import datosTiposExcursiones from "./datos/datosTipoExcursion";
import datosExcursiones from "./datos/datosExcursiones";

const Excursiones = ({ children }) => {
  const [data, setData] = useState({ dato: [] });

  const mostrarContenido = valor =>
    setData({
      dato: datosExcursiones.filter(item => item.tipo === valor.tipo),
      ...valor
    });

  return (
    <>
      <Hero texto="Excursiones" />
      <div className="container">
        <TiposExcursiones
          data={datosTiposExcursiones}
          onOptionClick={mostrarContenido}
        />
      </div>

      {data !== {} && (
        <div className="container">
          <Hero
            texto={data.texto}
            subtexto={data.info}
            styleClass={data.cssClass}
          />
          <ListaItems data={data.dato} styleClass={data.cssClass} />
        </div>
      )}
    </>
  );
};

export default Excursiones;
