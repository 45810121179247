const tipos = [
  {
    key: 0,
    texto: "Lúdico-Recreativas",
    cssClass: "",
    tipo: "act00",
    info: `
      En Aristas somos especialistas en diseñar actividades para que los niños
      aprendan jugando. A través de actividades sensoriales, cognitivas y
      lúdicas, los niños aprenderán diferentes contenidos curriculares,
      trabajarán en equipo, se divertirán, imaginarán, sonreirán...
      Sin lugar a dudas, nuestro equipo hará que lo pasen en grande.
    `
  },
  {
    key: 1,
    texto: "Ocio-culturales",
    cssClass: "",
    tipo: "act01",
    info: `
      Conoceremos diferentes culturas, animales, lugares arquitectónicos,
      cuevas erosionadas de maneras asombrosas, museos ...
      Y, siempre, de la manera más divertida. Acompañados por grandes
      profesionales, quienes ofrecerán a los niños diversión, juegos y
      actividades adaptadas a su edad.
    `
  },
  {
    key: 2,
    texto: "Aventura",
    cssClass: "",
    tipo: "act02",
    info: `
      Calzarse las botas o el bañador y prepararse para divertirse a lo largo
      de todo el día. Recordar que hay un ocio increíblemente entretenido fuera
      de las pantallas y los videojuegos. Y, sobre todo, pasarlo genial en una
      excursión deportiva, saludable y entretenida para todos.
    `
  },
  {
    key: 3,
    texto: "Oficios",
    cssClass: "",
    tipo: "act03",
    info: ``
  },
  {
    key: 4,
    texto: "Parques Temáticos",
    cssClass: "",
    tipo: "act04",
    info: ``
  }
];

export default tipos;
