import React from "react";

import Item from "./excursiones/item";

const ListaItems = ({ data, styleClass }) => {
  const size = data.length;
  const optionClass =
    size <= 2 ? "is-half" : size === 3 ? "is-one-third" : "is-one-quarter";

  return (
    <div className="columns is-multiline is-centered">
      {data.map(item => (
        <Item key={item.key} item={item} optionClass={optionClass} styleClass={styleClass} />
      ))}
    </div>
  );
};

export default ListaItems;
